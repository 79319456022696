<template>
  <CRow>
    <CCol sm="12">

      <CCard v-if="leistungen">

        <CCardHeader>
          <CRow>
            <CCol sm="8">
              <CIcon name="cil-window"/>
              <h5>Übersicht über alle Leistungen</h5>
            </CCol>
            <CCol sm="4" class="text-right">
              <CButton color="success" size="sm" v-on:click="newEntry"><CIcon name="cil-library-add"/> Neue Leistung</CButton>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol sm="12">

              <CTabs>
                <CTab add-tab-classes="mt-1">
                  <template slot="title">
                    <CIcon name="cil-thumb-up"/>
                    <span>Aktiv</span>
                  </template>
                  <Table :leistungen="aktiv" v-model="reload"/>
                </CTab>
                <CTab add-tab-classes="mt-1">
                  <template slot="title">
                    <CIcon name="cil-thumb-down"/>
                    <span>Inaktiv</span>
                  </template>
                  <Table :leistungen="inaktiv" v-model="reload"/>
                </CTab>
              </CTabs>
            </CCol>
          </CRow>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import Table from '@/views/Leistungen/Table'

export default {
  name: 'MitarbeiterIndex',
  components: {
    Table
  },
  data () {
    return {
      leistungen: '-',
      reload: ''
    }
  },
  computed: {
    aktiv () {
      if (this.leistungen) {
        return this._.filter(this.leistungen, function (lst) {
          return lst.aktiv
        })
      } else {
        return null
      }
    },
    inaktiv () {
      if (this.leistungen) {
        return this._.filter(this.leistungen, function (lst) {
          return !lst.aktiv
        })
      } else {
        return null
      }
    }
  },
  mounted () {
    this.loadLeistungen()
  },
  watch: {
    reload: function () {
      if (this.reload) {
        this.loadLeistungen()
        this.reload = ''
      }
    }
  },
  methods: {
    newEntry () {
      this.$router.push({ path: '/leistungen/neu' })
    },
    loadLeistungen () {
      Vue.axios.get('/leistung/get')
        .then((response) => {
          this.leistungen = response.data
        })
    }
  }
}
</script>
